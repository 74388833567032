import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ButtonLink } from '../components/button-link';
import { ContactSection } from '../components/contact-section';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { InstagramWidget } from '../components/instagram-widget';

function SuccessPage(): React.ReactElement {
  return (
    <>
      <SEO
        title="Success"
        description="Welcome to Adore Beauty and Cosmetic Tattoo an elite beauty and cosmetic tattoo studio located in the beautiful coastal town of Port Macquarie NSW. Relax and unwind with our customised cosmetic tattoo and beauty services."
      />
      <Layout>
        <Hero />
        <InstagramWidget />
      </Layout>
    </>
  );
}

function Hero(): React.ReactElement {
  return (
    <div className="relative hidden sm:block">
      {/* <div className="absolute md:hidden -top-24">
        <StaticImage
          quality={90}
          src="../images/hero-bg.png"
          alt=""
          width={700}
          className="flex-1 w-full object-full"
        />
      </div> */}
      <div className="absolute inset-0 flex">
        <StaticImage
          quality={90}
          src="../images/home-hero.jpeg"
          alt=""
          className="flex-1 object-cover"
        />
        <div className="absolute inset-0 flex">
          <div className="flex-1 hero-bg-filter" />
        </div>
      </div>
      <div className="relative w-full px-4 py-32 mx-auto sm:px-6 lg:px-8 max-w-screen-2xl">
        <h1 className="text-4xl font-semibold text-custom-gradient">
          Success!
        </h1>
        <p className="text-2xl font-medium ">
          Thank you, our team will get back
          <br />
          to you shortly!
        </p>
        <p className="mt-5">
          <ButtonLink to="/">Return Home</ButtonLink>
        </p>
      </div>
    </div>
  );
}

export default SuccessPage;
